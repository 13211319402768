import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('@/pages/MapPage'),
    // component: () => import('@/components/HelloWorld'),
    children: [
      // { path: '', redirect: '/' },
      {
        path: '/',
        name: 'home',
        component: () => import('@/pages/HomePage'),
        children: [
          // { path: '', redirect: '/' },
          {
            path: '/detail/:id',
            name: 'detail',
            component: () => import('@/pages/subpages/DetailPage')
          },
          {
            path: '/filter',
            name: 'filter',
            component: () => import('@/pages/subpages/FilterPage')
          },
          {
            path: '/contract/price',
            name: 'selectPrice',
            component: () => import('@/pages/contract/SelectPricePage')
          },
          {
            path: '/contract/apply',
            name: 'apply',
            component: () => import('@/pages/contract/ApplyPage')
          },
          {
            path: '/contract/sector',
            name: 'selectSector',
            component: () => import('@/pages/contract/SelectSectorPage')
          },
        ]
      },
      // {
      //   path: '/pin',
      //   name: 'pin',
      //   component: () => import('@/pages/MorePage'),
      //   children: [
      //     // { path: '', redirect: '/' },
      //     {
      //       path: 'servicechecklist',
      //       name: 'servicechecklist',
      //       component: () => import('@/pages/pin/ServiceCheckPage')
      //     },
      //   ]
      // },
      {
        path: '/pin',
        name: 'pin',
        component: () => import('@/pages/PinPage'),
        children: [
          // { path: '', redirect: '/' },
          {
            path: 'servicechecklist',
            name: 'mypage2',
            component: () => import('@/pages/pin/ServiceCheckPage')
          },
        ]
      },
      {
        path: '/more',
        name: 'more',
        component: () => import('@/pages/MorePage'),
        children: [
          // { path: '', redirect: '/' },
          {
            path: 'mypage',
            name: 'mypage',
            component: () => import('@/pages/more/MypagePage')
          },
          {
            path: 'account',
            name: 'more-account',
            component: () => import('@/pages/more/AccountPage')
          },
          {
            path: 'notice',
            name: 'notice',
            component: () => import('@/pages/more/NoticePage')
          },
          {
            path: 'servicecenter',
            name: 'servicecenter',
            component: () => import('@/pages/more/ServicecenterPage')
          },
          {
            path: 'settings',
            name: 'settings',
            component: () => import('@/pages/more/SettingsPage')
          },
          {
            path: 'terms',
            name: 'terms',
            component: () => import('@/pages/more/TermsPage')
          },
        ]
      },
      {
        path: '/account',
        name: 'account',
        children: [
          // { path: '', redirect: '/' },
          {
            path: 'login',
            name: 'login',
            component: () => import ('@/pages/account/LoginPage')
          },
          {
            path: 'agree',
            name: 'agree',
            component: () => import ('@/pages/account/AgreePage')
          },
        ]
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('@/pages/MorePage'),
        children: [
          {
            path: 'account',
            name: 'users-account',
            component: () => import('@/pages/more/AccountPage')
          },
        ]
      }
    ]
  },
  {
    path: '/map',
    name: 'mapsViwe',
    component: () => import(/* webpackChunkName: "about" */ '@/pages/test/MapPage.vue')
  },
  {
    path: '/auth',
    name: 'auth',
    children: [
      // { path: '', redirect: '/' },
      {
        path: 'naver',
        name: 'naver',
        component: () => import ('@/pages/auth/NaverAuth')
      },
      {
        path: 'kakao',
        name: 'kakao',
        component: () => import ('@/pages/auth/KakaoAuth')
      },
    ]
  },
  // {
  //   path: '/pin',
  //   name: 'pin',
  //   component: () => import('@/pages/PinPage')
  // },
  // {
  //   path: '/more',
  //   name: 'more',
  //   component: () => import('@/pages/MorePage')
  // },
  { path: '/test1', component: () => import('@/pages/test/TestPage1') },
  { path: '/test2', component: () => import('@/pages/test/TestPage2') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
