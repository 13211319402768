import { createApp } from 'vue'
import { createNaverMap } from "vue3-naver-maps";
import { useToast } from 'vue-toast-notification'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import api from './apis/'

// import VueGtag from 'vue-gtag-next'

import 'vue-toast-notification/dist/theme-bootstrap.css'

import '@/assets/scss/common.scss'
import '@/assets/scss/index.scss'


const app = createApp(App)
app.config.globalProperties.$API = api
app.use(createNaverMap, {
  clientId: process.env.VUE_APP_NAVER_CLOUD_CLIENT_ID, // Required
  // category: "ncp", // Optional
  // subModules: [], // Optional
})
app.use(store)
app.use(router)

// if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
//   app.use(VueGtag, {
//     property: { id: process.env.VUE_APP_GOOGLE_ANALYTICS }
//   })
// }

app.mixin({
  methods: {
    comma (num, isWon) {
      if (num == '') return ''
      let returnVal = num
      if (num == 0) return '무료'
      if (!isNaN(Number(num))) {
        returnVal = Math.round(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      } else {
        isWon = false
      }
      return returnVal + (isWon ? '원' : '')
    },
    gotoPage (path) {
      this.$router.push(path)
      window.scrollTo(0, 0)
    },
    autoLogin () {
      if (sessionStorage.getItem('keyId')) {
        this.$store.state.users.isLogin = true
        this.$store.state.users.keyId = sessionStorage.getItem('keyId')
        this.$store.state.users.snsType = sessionStorage.getItem('snsType')
        this.$store.state.users.pinOfficeList = sessionStorage.getItem('pinOfficeList') != '' ? JSON.parse(sessionStorage.getItem('pinOfficeList')) : []
        this.$store.state.users.serviceCheckList = sessionStorage.getItem('serviceCheckList') != '' ? JSON.parse(sessionStorage.getItem('serviceCheckList')) : []
        if (sessionStorage.getItem('agreeTerms') != 'true') {
          this.$nextTick(() =>{
            this.gotoPage('/account/agree')
          })
        // } else if (isRedirect) {
        //   this.$router.replace('/')
        }
        window.dataLayer.push({
          'event': 'sign_in',
          'user_id': sessionStorage.getItem('keyId'),
        })
      } else {
        this.$store.state.users.isLogin = false
        this.$store.state.users.keyId = ''
        this.$store.state.users.snsType = ''
        this.$store.state.users.pinOfficeList = []
        this.$store.state.users.serviceCheckList = []
      }
    },
    isLogin (loginFlag, data, snsType) {
      if (loginFlag) {
        sessionStorage.setItem('keyId', data.keyId)
        sessionStorage.setItem('agreeTerms', data.agreeTerms)
        sessionStorage.setItem('snsType', snsType ? snsType : '')
        sessionStorage.setItem('pinOfficeList', JSON.stringify(data.pinOfficeList))
        sessionStorage.setItem('serviceCheckList', JSON.stringify(data.serviceCheckList))
        this.$store.state.users.email = data.email
        this.$store.state.users.name = data.name
        this.$store.state.users.nickname = data.nickname
        this.$store.state.users.phone = data.phone
      } else {
        sessionStorage.setItem('keyId', '')
        sessionStorage.setItem('agreeTerms', '')
        sessionStorage.setItem('snsType', '')
        sessionStorage.setItem('pinOfficeList', '[]')
        sessionStorage.setItem('serviceCheckList', '[]')
        this.$store.state.users.email = ''
        this.$store.state.users.name = ''
        this.$store.state.users.nickname = ''
        this.$store.state.users.phone = ''
      }
      
      this.autoLogin()
    },
    togglePin (id) {
      if (!this.$store.state.users.keyId) {
        this.toast('로그인이 필요한 서비스입니다.')
        this.gotoPage('/account/login')
        return
      }
      if (sessionStorage.getItem('agreeTerms') != 'true') {
        this.toast('약관 동의가 필요합니다.')
        this.gotoPage('/account/agree')
        return
      }
      if (this.$store.state.users.serviceCheckList.includes(id)) {
        this.$store.state.users.pinOfficeList.splice(this.$store.state.users.pinOfficeList.indexOf(id), 1)
        this.$store.state.users.serviceCheckList.splice(this.$store.state.users.serviceCheckList.indexOf(id), 1)
        this.toast('[서비스 체크 목록]에서 제거 되었습니다!')
        sessionStorage.setItem('serviceCheckList', JSON.stringify(this.$store.state.users.serviceCheckList))
        this.$API.account.serviceCheckList(this.$store.state.users.keyId, this.$store.state.users.serviceCheckList)
      } else if (this.$store.state.users.pinOfficeList.includes(id)) {
        this.$store.state.users.pinOfficeList.splice(this.$store.state.users.pinOfficeList.indexOf(id), 1)
        this.toast('관심목록에서 제거되었습니다!')
      } else {
        this.$store.state.users.pinOfficeList.push(id)
        this.toast('관심목록에 추가되었습니다!')
      }
      sessionStorage.setItem('pinOfficeList', JSON.stringify(this.$store.state.users.pinOfficeList))
      this.$API.account.pinList(this.$store.state.users.keyId, this.$store.state.users.pinOfficeList)
    },
    addServiceCheck (id) {
      if (!this.$store.state.users.keyId) {
        this.toast('로그인이 필요한 서비스입니다.')
        this.gotoPage('/account/login')
        return
      }
      if (sessionStorage.getItem('agreeTerms') != 'true') {
        this.toast('약관 동의가 필요합니다.')
        this.gotoPage('/account/agree')
        return
      }
      if (this.$store.state.users.pinOfficeList.indexOf(id) === -1) {
        this.$store.state.users.pinOfficeList.push(id)
        sessionStorage.setItem('pinOfficeList', JSON.stringify(this.$store.state.users.pinOfficeList))
        this.$API.account.pinList(this.$store.state.users.keyId, this.$store.state.users.pinOfficeList)
      }
      this.$store.state.users.serviceCheckList.push(id)
      sessionStorage.setItem('serviceCheckList', JSON.stringify(this.$store.state.users.serviceCheckList))
      this.$API.office.serviceCheckCount(id)
      this.$API.account.serviceCheckList(this.$store.state.users.keyId, this.$store.state.users.serviceCheckList)
        .then(res => {
          console.log(res.data)
        })
        .catch(err => {
          console.log(err)
        })
    },
    sendMail (isAlliance) {
      let title = '[세컨드펭귄] 문의드립니다.'
      if (isAlliance) title = '[세컨드펭귄] 제휴문의드립니다.'
      window.open(`mailto:cs@2peng.kr?subject=${title}`)
    },
    callCopy (val) {
      this.toast('링크가 복사되었습니다.')
      window.navigator.clipboard.writeText(val)
    },
    share (description, url) {
      let shareObject = {
        title: '세컨드펭귄',
        text: description,
        url: url,
      }

      if (navigator.canShare && navigator.canShare(shareObject)) {
        navigator.share(shareObject)
          .then(() => {
            this.toast('공유되었습니다.')
          })
      } else {
        this.callCopy(url)
      }
    },
    toast (msg) {
      useToast().open({
        type: 'default',
        message: msg,
        position: 'bottom',
      })
      // success, info, warning, error, default
    },
    callMapList (isLocation, x, y) {
      let map = this.$store.state.map
      this.$store.state.isLoading = true
      this.$store.state.officeList = []
      let mapFilter = this.$store.state.mapFilter

      if (!mapFilter.keyword && !x) {
        isLocation = true
      }

      if (x) {
        mapFilter['x'] = x
        mapFilter['y'] = y
      } else {
        mapFilter['x'] = ''
        mapFilter['y'] = ''
        // mapFilter['x'] = map.center.x
        // mapFilter['y'] = map.center.y
      }

      if (isLocation == true) {
        mapFilter['nex'] = map.bounds._ne.x
        mapFilter['ney'] = map.bounds._ne.y
        mapFilter['swx'] = map.bounds._sw.x
        mapFilter['swy'] = map.bounds._sw.y
      } else {
        mapFilter['nex'] = ''
        mapFilter['ney'] = ''
        mapFilter['swx'] = ''
        mapFilter['swy'] = ''
      }
      // if (!isFirst) {
      //   // mapFilter['keyword'] = ''
      //   // mapFilter['x'] = ''
      // }
      this.$API.map.list(mapFilter)
        .then(res => {
          let returnList = res.data.officesList
          if (this.$store.state.aroundPrice !== '') {
            returnList = returnList.filter(e => {
              let monthPrice = Object.values(e.price.sole[e.price.sole.length-1])[0] / Object.keys(e.price.sole[e.price.sole.length-1])[0].split('m')[0]
              if (this.$store.state.aroundPrice === 10) {
                return Math.floor(monthPrice / 10000) >= 10
              } else if (this.$store.state.aroundPrice == 6) {
                return Math.floor(monthPrice / 10000) >= 6 && Math.floor(monthPrice / 10000) < 10
              } else {
                return Math.floor(monthPrice / 10000) == this.$store.state.aroundPrice
              }
            })
          }

          // returnList.forEach(e => {
          //   if (e.img.imgList.length == 0) {
          //     e.img.imgList = ['/no-img.png']
          //   } else {
          //     e.img.imgList = e.img.imgList.filter(e => e.indexOf('pstatic.net') === -1)
          //     if (e.img.imgList.length == 0) {
          //       e.img.imgList = ['/no-img-naver.png']
          //     }
          //   }
          // })
          this.$store.state.officeList = returnList

          this.$store.state.showLocation = false
          if (!isLocation && (res.data.kakaoSearch == true || res.data.subwayName)) {
            this.$store.state.showLocation = true
          }
          this.$store.state.isMapMove = true

          if (!(res.data.kakaoSearch || res.data.subwayName) && returnList.length == 0) {
            isLocation = true
          }

          if (!isLocation) {
            let x = res.data.x
            let y = res.data.y

            if (this.$store.state.windowWidth > 800) {
              let mapWidth = map.size.width - 360
              let alpha = (map.bounds._ne.x - map.bounds._sw.x)*(mapWidth / map.size.width)*(1-(mapWidth / map.size.width))
              x = x - alpha
            }
            map.panTo(new window.naver.maps.LatLng(y, x))
            setTimeout(() => {
              this.$store.state.isMapMove = false
            }, 1300)
          } else {
            this.$store.state.isMapMove = false
          }
        })
        .catch((err) => {
          console.log(err)
          // this.isLogin(false)
        })
        .then(() => {
          this.$store.state.isLoading = false
        })
    },
    moveMap (x, y) {
      this.$store.state.map.panTo(new window.naver.maps.LatLng(y, x))
    },
    zoomImgs (imgs, i) {
      this.$store.state.zoomImgList = imgs
      if (i) {
        this.$nextTick(() => {
          document.getElementById('imgSwiper').activeIndex = i
        })
      }
    },
    closeBottomSheetGuide () {
      localStorage.setItem('showBottomSheetGuide', true)
      this.$store.state.showBottomSheetGuide = false
    },
    closeModal () {
      this.$store.state.modalType = ''
      this.$store.state.modalFunc = null
    },
  }
})

app.mount('#app')
// createApp(App).use(store).use(router).mount('#app')
