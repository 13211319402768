<template>
  <div class="wrap navbar">
    <div class="navbar-list">
      <div class="logo-wrap">
        <img @click="gotoPage('/')" class="pointer" src="@/assets/icons/logo-snb.svg">
      </div>
      <div class="nav" @click="navClick('/')" :class="$route.path == '/' || ['/detail', '/filter', '/contract'].some(e => $route.path.indexOf(e) !== -1) ? 'active' : ''">
        <img :src="require(`@/assets/icons/desktop/map${$route.path == '/' || ['/detail', '/filter', '/contract'].some(e => $route.path.indexOf(e) !== -1) ? '-w' : ''}.svg`)">
        <p>지도 홈</p> 
      </div>
      <div class="nav" @click="navClick('/pin')" :class="$route.path.includes('/pin') ? 'active' : ''">
        <img :src="require(`@/assets/icons/desktop/pin${$route.path.includes('/pin') ? '-w' : ''}.svg`)">
        <p>관심목록</p>
        <p class="pin-count">{{ $store.state.users.pinOfficeList.filter(x => !$store.state.users.serviceCheckList.includes(x)).length }}</p>
      </div>
      <div class="nav" @click="navClick('/more')" :class="$route.path.includes('/more') ? 'active' : ''">
        <img :src="require(`@/assets/icons/desktop/more${$route.path.includes('/more') ? '-w' : ''}.svg`)">
        <p>더보기</p> 
      </div>
      <!-- <div class="nav" v-for="(d, i) in navList" :key="i" @click="navClick(d.path)" :class="$route.path.includes(d.path) ? 'active' : ''">
        <img :src="require(`@/assets/icons/${d.icon}${$route.path.includes(d.path) ? '-b' : ''}.svg`)">
        <p>{{ d.name }}</p> 
      </div> -->
    </div>
    <div>
      <!-- <img class="icon pointer" src="@/assets/icons/mobile/bell.svg"> -->
      <div class="icon-wrap">
        <p v-if="$store.state.users.isLogin" @click="gotoPage('/users')" class="user">{{ $store.state.users.snsType[0].toUpperCase() }}</p>
        <img v-else @click="gotoPage('/account/login')" class="icon pointer" src="@/assets/icons/user-snb.svg">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      navList: [
        // {
        //   id: 'map',
        //   name: '지도 홈',
        //   icon: 'map',
        //   path: '/'
        // },
        // {
        //   id: 'community',
        //   name: '커뮤니티',
        //   icon: 'users',
        //   path: '/community'
        // },
        {
          id: 'pin',
          name: '관심목록',
          icon: 'pin',
          path: '/pin'
        },
        {
          id: 'more',
          name: '더보기',
          icon: 'dot',
          path: '/more'
        },
      ]
    }
  },
  mounted () {
  },
  methods: {
    navClick (path) {
      this.gotoPage(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 120;
  background-color: $primary1;
  border-right: 1px solid $neutral10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 48px;
  &-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.06);
    .logo-wrap {
      height: 131px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-top: 24px;
    }
    .nav {
      cursor: pointer;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      padding: 10px;
      font-size: 14px;
      font-weight: 700;
      color: $neutral90;
      &.active {
        color: $neutral0;
        background-color: $pn70;
      }
      img {
        width: 24px;
        height: 24px;
      }
      .pin-count {
        position: absolute;
        top: 15px;
        right: 15px;
        height: 18px;
        display: flex;
        align-items: center;
        color: $neutral0;
        font-size: 12px;
        background-color: $secondary50;
        padding: 0 6px;
        border-radius: 9px;
      }
    }
  }
  .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    .user {
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $pn80;
      border-radius: 16px;
      color: $neutral10;
      font-size: 20px;
      font-weight: 300;
    }
    .icon {
      cursor: pointer;
      width: 32px;
      height: 32px;
    }
  }
}
</style>