import axios from 'axios'
const ORIGIN_ADDRESS = 'https://api.2peng.kr'
// const ORIGIN_ADDRESS = 'http://127.0.0.1:8000' // 개발서버
const API_ADDRESS = ORIGIN_ADDRESS + '/api/v1'

axios.defaults.headers.common = {
  'Content-Type': 'application/json',
  // 'Content-Type': 'application/x-www-form-urlencoded',
  // "Context-Type": "multipart/form-data",
  // 'Access-Control-Allow-Origin': '*',
  // 'Cache-Control': 'no-cache'
}

export default {
  check: {
    healthCheck: () => {
    //   const fd = new FormData()
      return axios.get(`${ORIGIN_ADDRESS}/healthCheck`)
    //   return axios.get(`${ORIGIN_ADDRESS}/healthCheck`, fd)
    },
  },
  account: {
    autoLogin: (keyId) => {
      let data = {
        'keyId': keyId,
      }
      return axios.post(`${API_ADDRESS}/account/autoLogin`, data)
    },
    snsLogin: (snsType, userData) => {
      let data = {
        'snsType': snsType,
        'userData': userData
      }
      return axios.post(`${API_ADDRESS}/account/snsLogin`, data)
    },
    withdraw: (keyId) => {
      let data = {
        'keyId': keyId,
      }
      return axios.post(`${API_ADDRESS}/account/withdraw`, data)
    },
    agreeTerms: (keyId, serviceFlag, personalInformationFlag, gpsFlag, marketingFlag) => {
      let data = {
        'keyId': keyId,
        'serviceFlag': serviceFlag,
        'personalInformationFlag': personalInformationFlag,
        'gpsFlag': gpsFlag,
        'marketingFlag': marketingFlag,
      }
      return axios.post(`${API_ADDRESS}/account/agreeTerms`, data)
    },
    pinList: (keyId, pinList) => {
      let data = {
        'keyId': keyId,
        'pinList': pinList
      }
      return axios.post(`${API_ADDRESS}/account/pinList`, data)
    },
    serviceCheckList: (keyId, serviceCheckList) => {
      let data = {
        'keyId': keyId,
        'serviceCheckList': serviceCheckList
      }
      console.log(data)
      return axios.post(`${API_ADDRESS}/account/serviceCheckList`, data)
    },
  },
  map: {
    list: (filter) => {
      return axios.post(`${API_ADDRESS}/map/getList`, filter)
    }
  },
  subway: {
    list: (keyword) => {
      let data = {
        'keyword': keyword,
      }
      return axios.post(`${API_ADDRESS}/subway/getSubway`, data)
    }
  },
  office: {
    getDetail: (id) => {
      let data = {'id': id}
      return axios.post(`${API_ADDRESS}/office/getDetail`, data)
    },
    serviceCheckCount: (id) => {
      let data = {'id': id}
      return axios.post(`${API_ADDRESS}/office/serviceCheckCount`, data)
    },
    getPinList: (pinList) => {
      let data = {'pinOfficeList': pinList}
      console.log(data)
      return axios.post(`${API_ADDRESS}/office/getPinList`, data)
    },
    getServiceCheckList: (serviceCheckList) => {
      let data = {'serviceCheckList': serviceCheckList}
      console.log(data)
      return axios.post(`${API_ADDRESS}/office/getServiceCheckList`, data)
    },
  },
  review: {
    getReviews: (id) => {
      let data = {
        'officeId': id
      }
      return axios.post(`${API_ADDRESS}/review/getReviews`, data)
    },
    upsertReview: (keyId, nickname, officeId, rating, comment, recommend) => {
      let data = {
        'keyId': keyId,
        'nickname': nickname,
        'officeId': officeId,
        'rating': rating,
        'comment': comment,
        'recommend': recommend,
      }
      return axios.post(`${API_ADDRESS}/review/upsertReview`, data)
    },
    deleteReview: (keyId, id) => {
      let data = {
        'keyId': keyId,
        'officeId': id
      }
      return axios.post(`${API_ADDRESS}/review/deleteReview`, data)
    },
  },
  contract: {
    send: (
      keyId,
      userName,
      phone,
      sector,
      inquiry,
      officeId,
      placeName,
      priceType,
      totalPrice,
      period,
      deposit,
      device,
      os,
      browser,
    ) => {
      let data = {
        'keyId': keyId,
        'userName': userName,
        'phone': phone,
        'sector': sector,
        'inquiry': inquiry,
        'officeId': officeId,
        'placeName': placeName,
        'priceType': priceType,
        'totalPrice': totalPrice,
        'period': period,
        'deposit': deposit,
        'device': device,
        'os': os,
        'browser': browser,
      }
      return axios.post(`${API_ADDRESS}/contract/send`, data)
    },
  }
}
