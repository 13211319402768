<template>
  <div class="wrap">
    <div class="header">
      <img @click="$router.go(-1)" class="back" src="@/assets/icons/chevron-back.svg">
      <p>{{pageTitle}}</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pageTitle: '',
      titleList: {
        '/filter': '필터',
        '/contract/price': '비상주 신청하기',
        '/contract/apply': '비상주 신청하기',
        '/contract/sector': '업종선택',
        '/pin': '관심목록',
        '/pin/servicechecklist': '서비스 체크 목록',
        '/more': '더보기',
        '/more/mypage': '마이페이지',
        '/more/account': '회원정보 관리',
        '/users/account': '회원정보 관리',
        '/more/servicecenter': '고객센터',
        '/more/settings': '설정',
        '/more/terms': '이용약관 및 정책',
      }
    }
  },
  mounted () {
    this.updateTitle()
  },
  methods: {
    updateTitle () {
      this.pageTitle = this.titleList[this.$route.path]
    }
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.updateTitle()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  .header {
    z-index: 120;
    height: 54px;
    background-color: $pn80;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $neutral0;
    position: relative;
    font-size: 17px;
    font-weight: 700;
    .back {
      cursor: pointer;
      position: absolute;
      left: 16px;
    }
  }
}
</style>