<template>
  <div class="wrap navbar">
    <div class="navbar-list">
      <div class="nav" @click="navClick('/')"
        :class="$route.path == '/' ? 'active' : ''">
        <img :src="require(`@/assets/icons/map${$route.path == '/' ? '-b' : ''}.svg`)">
        <p>지도홈</p>
      </div>
      <div class="nav" @click="navClick('/pin')"
        :class="$route.path == '/pin' ? 'active' : ''">
        <img :src="require(`@/assets/icons/pin${$route.path == '/pin' ? '-b' : ''}.svg`)">
        <p>관심목록</p>
        <p class="pin-count">{{ $store.state.users.pinOfficeList.filter(x => !$store.state.users.serviceCheckList.includes(x)).length }}</p>
      </div>
      <div class="nav" @click="navClick('/more')"
        :class="$route.path == '/more' ? 'active' : ''">
        <img :src="require(`@/assets/icons/dot${$route.path == '/more' ? '-b' : ''}.svg`)">
        <p>더보기</p>
      </div>
      <!-- <div class="nav" v-for="(d, i) in navList" :key="i" @click="navClick(d.path)"
        :class="$route.path.includes(d.path) ? 'active' : ''">
        <img :src="require(`@/assets/icons/${d.icon}${$route.path.includes(d.path) ? '-b' : ''}.svg`)">
        <p>{{ d.name }}</p> 
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  watch () {

  },
  data () {
    return {
      navList: [
        // {
        //   id: 'map',
        //   name: '지도홈',
        //   icon: 'map',
        //   path: '/'
        // },
        // {
        //   id: 'community',
        //   name: '커뮤니티',
        //   icon: 'users',
        //   path: '/community'
        // },
        {
          id: 'pin',
          name: '관심목록',
          icon: 'pin',
          path: '/pin'
        },
        {
          id: 'more',
          name: '더보기',
          icon: 'dot',
          path: '/more'
        },
      ]
    }
  },
  mounted () {
  },
  methods: {
    navClick (path) {
      this.gotoPage(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 120;
  background-color: #fff;
  height: 57px;
  &-list {
    display: flex;
    // justify-content: space-around;
    justify-content: center;
    gap: 50px;
    padding: 7px 0;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.06);
    .nav {
      cursor: pointer;
      position: relative;
      padding: 0 10px;
      color: $primary;
      font-size: 12px;
      font-weight: 500;
      color: $neutral50;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.active {
        color: $neutral100;
      }
      img {
        width: 20px;
        height: 20px;
      }
      .pin-count {
        position: absolute;
        top: -3px;
        right: 8px;
        height: 18px;
        display: flex;
        align-items: center;
        color: $neutral0;
        background-color: $secondary50;
        padding: 0 6px;
        border-radius: 9px;
      }
    }
  }
}
</style>