<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> | 
    <router-link to="/map">Map</router-link> | 
    <p>{{ windowWidth }}</p>
    <p>{{ $route.path }}</p>
  </nav> -->

  <TopBar v-if="windowWidth <= 800 && $route.path != '/' && !['/detail'].some(e => $route.path.indexOf(e) !== -1)"/>

  <div class="main">
    <SnbBar v-if="windowWidth > 800"/>

    <div class="router">
      <router-view />
    </div>
  </div>
  <NavBar v-if="windowWidth <= 800"/>

  <div v-if="showBottomSheetGuide && windowWidth <= 800">
    <div class="guide-nav guide-nav1"></div>
    <div class="guide-nav guide-nav2"></div>
    <div class="guide-all" @click="closeBottomSheetGuide()"></div>
  </div>

  <div class="zoom" v-if="zoomImgList.length > 0">
    <div class="zoom-bg" @click="zoomClose()"></div>
    <div class="zoom-imgs">
      <swiper
        id="imgSwiper"
        :modules="modules"
        :slidesPerView="'auto'"
        :centeredSlides="true"
        :pagination="{ clickable: true }"
        :navigation="true"
        :loop="true"
        :loopedSlides="1"
        spaceBetween="1"
      >
        <swiper-slide v-for="(d, i) in zoomImgList" :key="i" @click="zoomClose()">
          <img :src="d">
        </swiper-slide>
      </swiper>
    </div>
  </div>

  <div class="splash flex" v-if="isSplash">
    <div class="flex flex1">
      <img src="@/assets/icons/logo-splash.svg">
    </div>
    <p class="splash-txt">세컨드펭귄과 함께 <b>성공적인 공유</b><br><b>오피스 환경을 구축</b>해 보세요!</p>
  </div>

  <div class="rating-wrap" v-if="isRating">
    <div class="rating-bg" @click="$store.state.isRating = false"></div>
    <div class="rating-page">
      <div class="rating-top">
        <p>{{ ratingOfficeData?.placeName }}</p>
      </div>
      <div class="rating-middle">
        <rating-view class="rating-view" size="bigger" :isPrimaryColor="true" @selectStar="updateStar" :val="$store.state.ratingStar"/>
        <p class="per">{{ $store.state.ratingStar }} / 5</p>
        <div class="rating-middle-wrap">
          <div class="recommend">
            <p>지인에게 추천할 의사가 있습니까?</p>
            <div class="recommend-check" @click="$store.state.ratingRecommend = !$store.state.ratingRecommend" :class="$store.state.ratingRecommend ? 'active' : ''">
              <img :src="require(`@/assets/icons/check-recommend${$store.state.ratingRecommend ? '-active' : ''}.svg`)">
              <p>네</p>
            </div>
          </div>
          <textarea v-model="$store.state.ratingComment" placeholder="작성시 참고해주세요!&#10;작성된 내용은 [상세페이지>리뷰]에 노출되며 다른 사용자들이 볼수 있습니다. 배려하는 마음을 담아 작성해 주시면 보시는 분들께 많은 도움이 됩니다." maxlength="2000"></textarea>
          <p class="count"><span>{{ $store.state.ratingComment.length }}</span>/2000</p>
        </div>
      </div>
      <div class="rating-bottom">
        <div class="bottom-btns">
          <p class="bottom-btn" @click="$store.state.isRating = false">취소</p>
          <p class="bottom-btn" @click="applyRating()">등록</p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" v-if="$store.state.modalType">
    <div class="modal-bg" @click="closeModal()"></div>
    <div class="modal-wrap">
      <div class="modal-top" v-if="$store.state.modalType == 'logout'">
        <img src="@/assets/icons/heart.svg">
        <p class="modal-title">고객님,<br>정말로 로그아웃을 하시겠습니까?</p>
        <p class="modal-content">언제나 최선을 다하는 세컨드펭귄이 되겠습니다!</p>
      </div>
      <div class="modal-top" v-if="$store.state.modalType == 'withdraw'">
        <img src="@/assets/icons/heart.svg">
        <p class="modal-title">고객님,<br>정말로 회원탈퇴를 하시겠습니까?</p>
        <p class="modal-content">아직 보여드리지못한 서비스가 더 있어요!</p>
      </div>
      <div class="modal-bottom">
        <p @click="$store.state.modalFunc();closeModal()">예</p>
        <p @click="closeModal()">아니오</p>
      </div>
    </div>
  </div>

  <div class="inquiry" v-if="$store.state.inquiryModal">
    <div class="inquiry-bg" @click="$store.state.inquiryModal = false"></div>
    <div class="inquiry-wrap">
      <div class="inquiry-top">
        <img src="@/assets/icons/inquiry.svg">
        <p>고객님!<br><b>매니저님의 직통 번호</b>로 연락주시면 <br>친절히 상담 드리겠습니다.</p>
        <p class="time">(문의 가능시간 : 오전 10시~오후 5시)</p>
      </div>
      <div class="inquiry-middle">
        <div class="info">
          <div class="info-row">
            <p>휴대폰 번호</p>
            <p>010-3882-5566</p>
          </div>
          <div class="info-row">
            <p>카카오톡 아이디</p>
            <p>2peng<span @click="goKakaotalk()">오픈채팅 바로가기</span></p>
          </div>
        </div>
        <div class="path">
          <p>[카카오톡 열기 > 하단의 오픈채팅 아이콘 > "세컨드펭귄" 검색]</p>
        </div>
      </div>
      <div class="inquiry-bottom">
        <p @click="$store.state.inquiryModal = false">확인</p>
      </div>
    </div>
    <div class="qr">
      <img src="@/assets/icons/qr-2peng-open.png">
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import TopBar from '@/pages/base/TopBar'
import SnbBar from '@/pages/base/SnbBar'
import NavBar from '@/pages/base/NavBar'
import RatingView from "@/components/RatingView"

export default {
  components: {
    TopBar,
    SnbBar,
    NavBar,
    RatingView,
    Swiper,
    SwiperSlide,
  },
  computed: {
    windowWidth () {
      return this.$store.state.windowWidth
    },
    isSplash () {
      return this.$store.state.isSplash
    },
    zoomImgList () {
      return this.$store.state.zoomImgList
    },
    isRating () {
      return this.$store.state.isRating
    },
    ratingOfficeData () {
      return this.$store.state.ratingOfficeData
    },
    showBottomSheetGuide () {
      return this.$store.state.showBottomSheetGuide
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.zoomClose()
        this.$store.state.hideSide = false
      }
    },
  },
  data () {
    return {
      modules: [Navigation, Pagination, Scrollbar, A11y],
      swiperOption: {
        slidesPerView: 1.8, //화면에 slide 노출 개수
        slidesPerGroup: 1, //swiper 시 개수
        spaceBetween: 10, //간격
        centeredSlides: true, //현재 slide 중앙정렬
        loop: true, // loop 여부
        loopedSlides: 2, //loop 시 duplicate 개수
        // autoplay: {
        //   delay: 1000,
        // },
      },
    }
  },
  beforeMount () {
    window.addEventListener('resize', this.pageResize)
    this.$store.state.mapFilter.x = Number(localStorage.getItem('x')) || 127.03647173203524
    this.$store.state.mapFilter.y = Number(localStorage.getItem('y')) || 37.50067802192446

    // if (navigator.geolocation) {
    //   if (localStorage.getItem('x')) return
    //   navigator.geolocation.getCurrentPosition(this.setPosition)
    // }
    if (!localStorage.getItem('showMainGuide')) {
      this.$store.state.showMainGuide = true
    }
    if (!sessionStorage.getItem('isSplash')) {
      setTimeout(() => {
        this.$store.state.isSplash = false
        sessionStorage.setItem('isSplash', true)
      }, 1000)
      console.log(sessionStorage.getItem('isSplash'))
      sessionStorage.setItem('isSplash', true)
      console.log(sessionStorage.getItem('isSplash'))
      this.$store.state.isSplash = true
    }
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.pageResize)
  },
  mounted () {
    this.$store.state.isMobile = /iphone|ipad|ipod|android/i.test(navigator.userAgent.toLowerCase())
    
    if (sessionStorage.getItem('keyId')) {
      this.$API.account.autoLogin(sessionStorage.getItem('keyId'))
        .then(res => {
          console.log(res.data)
          this.isLogin(true, res.data, res.data['snsType'])
        })
    } else {
      this.autoLogin()
    }
    this.pageResize()
  },
  methods: {
    pageResize () {
      this.$store.state.windowWidth = window.innerWidth
      this.$store.state.windowHeight = window.innerHeight
    },
    zoomClose () {
      this.$store.state.zoomImgList = []
    },
    setPosition (position) {
      localStorage.setItem('x', position.coords.longitude)
      localStorage.setItem('y', position.coords.latitude)
      this.$store.state.mapFilter.x = position.coords.longitude
      this.$store.state.mapFilter.y = position.coords.latitude
      this.callMapList()
    },
    // inputComment (e) {
    //   if (e.target.value.length > 2000) return
    //   this.ratingComment = e.target.value
    // },
    updateStar (type, i) {
      type
      this.$store.state.ratingStar = i
    },
    applyRating () {
      if (this.$store.state.ratingStar == 0 || this.$store.state.ratingComment == '') {
        this.toast('리뷰 정보를 입력해주세요.리뷰 정보를 입력해주세요.리뷰 정보를 입력해주세요.리뷰 정보를 입력해주세요.')
        return
      }
      this.$API.review.upsertReview(
        this.$store.state.users.keyId,
        this.$store.state.users.nickname,
        this.ratingOfficeData.id,
        this.$store.state.ratingStar,
        this.$store.state.ratingComment,
        this.$store.state.ratingRecommend
      )
        .then(res => {
          this.reviewData = res.data.data
          this.$store.state.isRating = false
          this.toast('별점이 등록되었습니다.')
        })
        .catch(() => {
          this.toast('서버 오류입니다. 잠시 후 다시 시도해주세요.')
        })
    },
    goKakaotalk () {
      window.open('https://open.kakao.com/o/sI618Eqg')
    }
  }
}
</script>

<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
}
nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.side {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: $sidemenu;
  height: 100%;
  z-index: 111;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.10);
  @include mobile {
    width: 100%;
    left: 0;
    top: 0;
    z-index: 111;
  }
  // transition: .3s;
  .side-wrap {
    overflow: auto;
    box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    width: $sidemenu;
    height: 100%;
    @include mobile {
      width: 100%;
      left: 0;
      top: 0;
      z-index: 111;
    }
  }
  &.hide {
    left: -360px!important;
    .subrouter {
      left: -360px!important;
    }
    .handle {
      img {
        transform: rotate(180deg);
      }
      &.sub {
        left: 360px!important;
      }
    }
  }
}
.handle {
  cursor: pointer;
  position: absolute;
  left: 360px;
  top: 50%;
  transform: translate(-1px, -50%);
  width: 24px;
  height: 50px;
  background-color: $neutral0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 120;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid $neutral20;
  border-left: none;
  border-radius: 0 4px 4px 0;
  @include mobile {
    display: none;
  }
  &.sub {
    left: 720px;
  }
}
.top {
  position: sticky;
  top: 0;
  z-index: 3;
}
.subrouter {
  position: absolute;
  z-index: 109;
  width: $submenu;
  height: 100%;
  overflow: auto;
  box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.10);
  background-color: $neutral0;
  top: 0;
  left: $sidemenu;
  @include mobile {
    width: 100%;
    left: 0;
    top: 0;
    z-index: 111;
  }
}
</style>

<style lang="scss" scoped>
.main {
  display: flex;
  flex: 1;
  overflow: hidden auto;
  .router {
    flex: 1;
    height: 100%;
  }
}
.zoom {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .zoom-bg {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
  }
  .zoom-imgs {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    height: 75%;
    @include mobile {
      width: 100%;
    }
    :deep(#imgSwiper) {
      width: 100%;
      height: 100%;
      .swiper-slide {
        display: flex;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12px;
        bottom: 24px;
      }
      .swiper-pagination-bullet {
        opacity: 0.5;
        width: 8px;
        height: 8px;
        background-color: $neutral0;
        transition: .3s;
        &-active {
          opacity: 1;
          width: 12px;
          height: 12px;
        }
      }
      .swiper-button-prev, .swiper-button-next {
        color: $neutral0;
      }
    }
  }
}
.splash {
  position: absolute;
  z-index: 1001;
  background-color: $primary50;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 90px 10%;
  flex-direction: column;
  .splash-txt {
    font-weight: 300;
    color: $neutral0;
    font-size: 20px;
    b {
      font-weight: 400;
    }
  }
}
.rating-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .rating-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .rating-page {
    width: 70%;
    background-color: $neutral0;
    z-index: 1;
    @include mobile {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .rating-top {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 58px;
      font-size: 20px;
      font-weight: 700;
    }
    .rating-middle {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      justify-content: center;
      padding: 20px 16px;
      border-top: 1px solid $neutral10;
      @include mobile {
        flex: 1;
        justify-content: flex-start;
      }
      .per {
        font-size: 14px;
      }
      .rating-middle-wrap {
        position: relative;
        width: 100%;
        border: 1px solid $neutral20;
        display: flex;
        flex-direction: column;
        .recommend {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 58px;
          padding: 0 20px;
          .recommend-check {
            cursor: pointer;
            display: flex;
            align-items: center;
            height: 30px;
            border: 1px solid $neutral20;
            border-radius: 15px;
            padding-left: 8px;
            padding-right: 12px;
            color: $neutral60;
            font-size: 14px;
            font-weight: 500;
            &.active {
              border-color: $primary50;
              color: $primary50;
            }
          }
        }
        textarea {
          width: 100%;
          height: 300px;
          resize: none;
          padding: 12px;
          border-top: 1px solid $neutral10;
          &::placeholder {
            color: $neutral40;
          }
        }
        .count {
          position: absolute;
          right: 8px;
          bottom: 8px;
          color: $neutral40;
          font-size: 12px;
          span {
            color: $neutral80;
          }
        }
      }
    }
    .rating-bottom {
      .bottom-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        .bottom-btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 700;
          color: $neutral50;
          background-color: $neutral0;
          border: 1px solid $neutral50;
          flex: 1;
          height: 50px;
          &:nth-child(2) {
            color: $neutral0;
            background-color: $pn60;
            border-color: $pn60;
          }
        }
      }
    }
  }
}
.guide-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 57px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  &.guide-nav1 {
    width: calc(50% - 40px);

  }
  &.guide-nav2 {
    width: calc(50% - 40px);
    left: unset;
    right: 0;

  }
}
.guide-all {
  cursor: pointer;
  position: fixed;
  z-index: 2000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .modal-wrap {
    z-index: 1;
    background-color: $neutral0;
    border: 1px solid $neutral10;
    border-radius: 8px;
    width: 300px;
    padding: 24px;
    .modal-top {
      // padding: 12px 20px;
      min-height: 100px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      .modal-title {
        margin-top: 6px;
        color: $neutral60;
      }
      .modal-content {
        margin-top: 16px;
        color: $neutral80;
      }
    }
    .modal-bottom {
      margin-top: 42px;
      display: flex;
      align-items: center;
      p {
        cursor: pointer;
        flex: 1;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $primary50;
        font-size: 14px;
        font-weight: 700;
        color: $primary50;
        background-color: $neutral0;
        &:nth-child(2) {
          color: $neutral0;
          background-color: $primary50;
        }
      }
    }
  }
}
.inquiry {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  .inquiry-bg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .inquiry-wrap {
    z-index: 1;
    background-color: $neutral0;
    border-radius: 24px;
    padding: 24px 0;
    width: 473px;
    .inquiry-top {
      padding: 0 44px;
      padding-left: 190px;
      position: relative;
      text-align: left;
      img {
        position: absolute;
        top: -74px;
        left: 27px;
      }
      .time {
        color: $secondary50;
        font-size: 14px;
        font-weight: 700;
        margin-top: 12px;
      }
    }
    .inquiry-middle {
      margin-top: 16px;
      .info {
        background-color: $primary1;
        padding: 32px 44px;
        display: flex;
        flex-direction: column;
        .info-row {
          display: flex;
          gap: 12px 16px;
          align-items: center;

          // justify-content: space-between;
          // flex-direction: ;
          p:first-child {
            width: 35%;
            text-align: right;
            color: $neutral60;
            font-size: 14px;
          }
          p:last-child {
            flex: 1;
            text-align: left;
            color: $neutral80;
            font-size: 20px;
            font-style: italic;
            font-weight: 500;
            span {
              cursor: pointer;
              margin-left: 28px;
              font-style: normal;
              text-decoration: underline;
              color: $primary50;
              font-size: 16px;
            }
          }
        }
        // .info-row:first-child {
        //   width: 35%;
        //   text-align: right;
        // }
      }
      .path {
        margin-top: 8px;
        color: $neutral60;
        font-size: 12px;
      }
    }
    .inquiry-bottom {
      padding: 0 60px;
      margin-top: 18px;
      // display: flex;
      // width: 326px;
      p {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 326px;
        height: 50px;
        border: 1px solid $primary50;
        color: $primary50;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .qr {
    position: absolute;
    left: calc(50% + 473px / 2 + 12px);
    img {
      width: 164px;
      height: 164px;
    }
  }
}
</style>