import { createStore } from 'vuex'

export default createStore({
  state: {
    windowWidth: 0,
    windowHight: 0,
    isMobile: false,
    isSplash: false,
    users: {
      isLogin: false,
      keyId: '',
      snsType: '',
      pinOfficeList: [],
      serviceCheckList: [],
      email: '',
      name: '',
      nickname: '',
      phone: '',
    },
    checkListData: [],
    isLoading: false,
    mapFilter: {
      keyword: '',
      // x: 127.03647173203524,
      // y: 37.50067802192446,
      transform: false,
    },
    tagSubwayList: [
      { 'name': '강남', 'x': "127.027977657727", 'y': "37.4980854411503", isActive: false },
      { 'name': '종각', 'x': "126.983133839231", 'y': "37.5702216815202", isActive: false },
      { 'name': '역삼', 'x': "127.036460414211", 'y': "37.5006600052986", isActive: false },
      { 'name': '여의도', 'x': "126.924638422261", 'y': "37.5211664706025", isActive: false },
      { 'name': '구로디지털단지', 'x': "126.901473089504", 'y': "37.4852533671941", isActive: false },
      { 'name': '잠실', 'x': "127.100215181119", 'y': "37.5133092702048", isActive: false },
      { 'name': '성수', 'x': "127.056057944077", 'y': "37.5445852156774", isActive: false },
      { 'name': '공덕', 'x': "126.95143307723", 'y': "37.5445344743163", isActive: false },
      { 'name': '홍대입구', 'x': "126.923764988566", 'y': "37.5568635281461", isActive: false },
      { 'name': '삼성', 'x': "127.063020940329", 'y': "37.5088119293814", isActive: false },
      { 'name': '시청(서울)', 'x': "126.977181235023", 'y': "37.5653372841193", isActive: false },
    ],
    stationName: '',
    selectSubway: {},
    aroundPrice: '',
    map: null,
    showLocation: false,
    isMapMove: false,
    officeList: [],
    zoomImgList: [],
    isRating: false,
    ratingOfficeData: {},
    ratingStar: 0,
    ratingComment: '',
    ratingRecommend: false,
    showMainGuide: false,
    showBottomSheetGuide: false,
    modalType: '',
    modalFunc: null,
    inquiryModal: false,
    hideSide: false,
    contract: {
      insertUserInfo: false,
      userName: '',
      phone: '',
      officeData: {},
      sector: '',
      sectorEtc: '',
      inquiry: '',
      priceType: '', // sole, corporation
      period: 0,
      totalPrice: 0,
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
})
