<template>
  <div class="rating" :size="size">
    <div v-if="isDisable" class="rating-wrap">
      <img v-for="(x, i) in 5" :key="i" src="@/assets/icons/star-gray.svg">
    </div>
    <div v-else-if="readOnly" class="rating-wrap">
      <img v-for="(x, i) in starFloorValue" :key="i" :src="require(`@/assets/icons/star${isPrimaryColor ? '-primary': ''}-full.svg`)">
      <img v-if="starValue % 1 != 0" :src="require(`@/assets/icons/star${isPrimaryColor ? '-primary': ''}-half.svg`)">
      <img v-for="(x, i) in 5-starFloorValue - (starValue % 1 != 0 ? 1 : 0)" :key="i" :src="require(`@/assets/icons/star${isPrimaryColor ? '-primary': ''}-empty.svg`)">
    </div>
    <div v-else class="rating-wrap">
      <img v-for="(x, i) in 5" :key="i" :src="require(`@/assets/icons/star${isPrimaryColor ? '-primary': ''}-${selectStar > i ? 'full' : 'gray'}.svg`)" @click="updateStar(i+1)" class="pointer">
    </div>
  </div>
</template>
<script>
export default {
  name: 'filterMenu',
  props: {
    val: {
      type: Number,
      description: 'value'
    },
    readOnly: {
      type: Boolean,
      description: 'read only'
    },
    isDisable: {
      type: Boolean,
      description: '',
      default: false
    },
    isPrimaryColor: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      description: 'type'
    },
    size: {
      type: String,
      description: 'size'
    }
  },
  watch: {
    val(newVal) {
      this.starValue = newVal
      this.starFloorValue = Math.floor(newVal)
    },
  },
  mounted () {
    this.starValue = this.val
    this.selectStar = this.val
    this.starFloorValue = Math.floor(this.val)
  },
  data () {
    return {
      selectStar: 0,
      starValue: 0,
      starFloorValue: 0,
    }
  },
  methods: {
    updateStar (i) {
      this.selectStar = i
      this.$emit('selectStar', this.type, i)
    }
  }
}
</script>

<style lang="scss" scoped>
.rating {
  .rating-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  img {
    width: 18px;
    height: 18px;
  }
}
.rating[size="small"] {
  .rating-wrap {
    gap: 1px;
  }
  img {
    width: 12px;
    height: 12px;
  }
}
.rating[size="big"] {
  img {
    width: 24px;
    height: 24px;
  }
}
.rating[size="bigger"] {
  img {
    width: 32px;
    height: 32px;
  }
}
</style>